import classnames from 'classnames';
import { ComponentProps, forwardRef } from 'react';

type Props = ComponentProps<'div'>;

const Card = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { className, children, ...cardProps } = props;

  const classNames = classnames(
    'flex flex-col flex-1 bg-white rounded py-6 pl-6 pr-8 ring-1 ring-gray-200 overflow-auto',
    className,
  );

  return (
    <div ref={ref} className={classNames} {...cardProps}>
      {children}
    </div>
  );
});
Card.displayName = 'Card';

export { Card };
