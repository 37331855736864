'use client';
import Image from 'next/image';
import React from 'react';

import { Button } from './Button';
import { Card } from './Card';

export function NotFound({ referrer }: { referrer?: string }) {
  return (
    <>
      <div className="flex justify-center min-h-screen mt-16">
        <div className="w-3/4 h-3/4">
          <Card className="flex flex-col justify-center items-center w-full h-full">
            <div className="relative w-36 h-36 mt-10">
              <Image src="/404.svg" alt="Empty folder" fill />
            </div>
            <h1 className="text-ice-grey-10 text-4xl font-bold mt-3">404</h1>
            <p className="text-ice-grey-10 font-semibold text-base mt-3 mb-7">
              This page could not be found!
            </p>

            <Button className="mb-24">
              <a href={referrer ? referrer : '/'}>Jump to Home</a>
            </Button>
          </Card>
        </div>
      </div>
    </>
  );
}
